<template>
  <div class="m">
    <newTitle type="filter">
      <template v-slot:CancelSelected>
        <div
          @click="quxiaoGxBtn"
          class="titleBox-right"
          style="text-decoration: unset; color: #2194f5"
        >
          Cancel selected
        </div>
      </template>
    </newTitle>

    <div class="selectBox">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item
          :name="index + 1"
          v-for="(item, index) in list"
          :key="index"
        >
          <template slot="title">
            {{ item.title }}
          </template>
          <div class="selectItem" v-for="(item2, index2) in item.childList">
            <!-- 第三层----------------- -->
            <div class="childBox" v-if="item2._child && item2._child.length">
              <el-collapse v-model="item2.activeNames" @change="handleChange">
                <el-collapse-item :name="item2.id">
                  <template slot="title" class="abc">
                    <van-checkbox
                      @change="titChildChangeFn(index, index2, item2.checked)"
                      v-model="item2.checked"
                      >{{ item2.name }}</van-checkbox
                    >
                  </template>
                  <div
                    class="selectItem"
                    v-for="(childitem, childindex) in item2._child"
                  >
                    <van-checkbox
                      @change="
                        itemChildChangeFn(
                          index,
                          index2,
                          childindex,
                          childitem.checked
                        )
                      "
                      v-model="childitem.checked"
                      >{{ childitem.name }}</van-checkbox
                    >
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <van-checkbox v-else v-model="item2.checked">{{
              item2.name
            }}</van-checkbox>
            <!-- 第三层----------------- -->
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="btnbtm">
      <el-button
        :class="{ btnNoCls: btnNoBr }"
        :disabled="btnNoBr"
        @click="btnOkFn"
        >Search</el-button
      >
    </div>
  </div>
</template>

<script>
import newTitle from "./Component_title.vue";
import { getCommonSearchSelect } from "@/api/hfIndex";
export default {
  components: { newTitle },
  data() {
    return {
      id: "",
      activeNames: [1, 2, 3, 4],
      checked: true,
      lanListPam: {
        zh: "sc",
        zh_tw: "tc",
        en: "en",
        ja: "jp",
        ko: "kr",
      },
      list: [
        // {
        //   title: "Theme",
        //   childList: [
        //     { checked: false, name: "Shop with Points" },
        //     { checked: false, name: "Transportation 1 Day Pass" },
        //     { checked: false, name: "All you can eat" },
        //   ],
        // },
      ],
    };
  },
  activated() {
    this.id = this.$route.query.id;
    this.list = [];
    this.getCommonSearchSelectFn(this.id);
  },
  methods: {
    itemChildChangeFn(i1, i2, i3, e) {
      // 小选控制全选
      // console.log('欸子',this.list[i1].childList[i2]._child);
      let allchildBr = this.list[i1].childList[i2]._child.every((item) => {
        return item.checked;
      });
      this.list[i1].childList[i2].checked = allchildBr;
    },
    titChildChangeFn(i1, i2, e) {
      // 全选控制小选
      // console.log('e',i1,i2,e);
      console.log(this.list[i1].childList[i2]);
      this.testTxt = this.list[i1].childList[i2]
      this.list[i1].childList[i2]._child.forEach((item) => {
        item.checked = e;
      });
    },
    async getCommonSearchSelectFn(id) {
      let res = await getCommonSearchSelect({ lang: this.lanPam, id });
      console.log(res);
      for (const key in res.data.data) {
        res.data.data[key].forEach((item) => {
          item.checked = false;
          if (item._child && item._child.length) {
            item.activeNames = [];
            console.log("ofof", res.data.data[key]);
            console.log("item", item);
            item._child.forEach((childitem) => {
              childitem.checked = false;
            });
          }
        });
        console.log(key);
        let title = "";
        if (key == "area_select") {
          title = "Area";
        } else if (key == "other_select") {
          title = "Other";
        } else if (key == "type_select") {
          title = "Type";
        }
        if(res.data.data[key] && res.data.data[key].length != 0){
          this.list.push({
            title,
            childList: res.data.data[key],
          });
        }
      }


      //  this.list.forEach(item => {
      //   item.childList.map(item2 => {
      //     this.$set(item2, 'checked', false)
      //     if (item2._child && item2._child.length) {
      //       item2._child.map(item3 => {
      //     this.$set(item3, 'checked', false)
      //       })
      //     }
      //   })
      // })
      console.log("最后的list", this.list);
    },
    quxiaoGxBtn() {
      this.$router.push(`/hfPage_list?id=${this.id}&l=${this.lanPam}`);

      // this.list.forEach((item) => {
      //   item.childList.forEach((item2) => {
      //     item2.checked = false;
      //     if (item2._child && item2._child.length) {
      //       item2._child.forEach((childitem) => {
      //         childitem.checked = false;
      //       });
      //     }
      //   });
      // });
    },
    btnOkFn() {
      // console.log(this.list);
      let paramsList = [];
      this.list.forEach((item) => {
        item.childList.forEach((item2) => {
          if (item2.checked) {
            paramsList.push(item2.id);
          }
          if (item2._child && item2._child.length) {
            item2._child.forEach((item3) => {
              if (item3.checked) {
                paramsList.push(item3.id);
              }
            });
          }
        });
      });

      console.log("参数列表", paramsList);
      this.$router.push(
        `/hfPage_list?id=${this.id}&l=${this.lanPam}&params=${JSON.stringify(
          paramsList
        )}`
      );
    },
    cancelFn() {
      this.list.forEach((item) => {
        item.childList.forEach((item2) => {
          item2.checked = false;
        });
      });
    },
    handleChange(val) {
      console.log(val);
    },
  },
  computed: {
    lanPam() {
      return this.lanListPam[localStorage.getItem("locale")] || "tc";
    },
    btnNoBr() {
      let everyVal = null;
      return this.list.every((item) => {
        return item.childList.every((item2) => {
          if (item2._child && item2._child.length) {
            everyVal = item2._child.every((item3) => {
              return !item3.checked;
            });
          }
          return !item2.checked && everyVal;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.m {
  height: 100vh;
  max-width: 766px;
  margin: 0 auto;
}
.titleBox {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 20px;
  .el-icon-arrow-left {
    font-size: 22px;
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .tit_Txt {
    height: 100%;
    font-size: 14px;
    color: #2194f5;
  }
}

/deep/.selectBox {
  margin-bottom: 33px;
  .el-collapse {
    border-top: 0;
    .el-collapse-item__header {
      border-color: #d4d4d4 !important;
      padding: 0 19px;
      height: 35px;
      padding-top: 10px;
    }
    .childBox {
      .el-collapse-item__header {
        padding: 0;
      }
    }
    .el-collapse-item__wrap {
      border-bottom: 0;
      padding: 0 19px;
      padding-top: 14px !important;
      .el-collapse-item__content {
        padding-bottom: 0;
        .selectItem {
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  // .el-checkbox__input .el-checkbox__inner {
  //   border-radius: 50%;
  //   width: 17px;
  //   height: 17px;
  // }
  // .el-checkbox__input.is-checked .el-checkbox__inner::after {
  //   display: none;
  // }

  // .el-checkbox__input.is-checked + .el-checkbox__label {
  //   color: #000;
  // }
}

.btnbtm {
  box-shadow: 0px -3px 0px #0000000a;
  padding: 11px 16px 25px;
  .el-button {
    background-color: #2194f5;
    color: #fff;
    width: 100%;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 17px;
    border: 0;
  }

  .btnNoCls {
    background-color: #e5e5e5;
  }
}
</style>
